import React, { FunctionComponent, ReactNode, SyntheticEvent } from 'react';
import { Box, FelaCSS, TButtonSize, TButtonTheme, TSizeValues } from '@bridebook/ui';
import LoadingCircle from '../../../../components/bbcommon/loading-circle/loading-circle';
import FelaButton from '../../../../components/fela/Button';
import mergeStyles from '../../../../fela-utils/merge-styles';
import { TIconPosition } from '../types';
import componentStyles from './button-shell.style';

interface IProps {
  theme?: TButtonTheme;
  size?: TButtonSize;
  w?: TSizeValues;
  icon?: ReactNode;
  iconPosition: TIconPosition;
  disabled?: boolean;
  loading?: boolean;
  equalPadding?: boolean;
  name?: string;
  id?: string;
  onClick?: (e: SyntheticEvent<any>) => any;
  onMouseEnter?: Function;
  ref?: Function;
  style?: FelaCSS;
  floatingIcon?: boolean;
}

const ButtonShell: FunctionComponent<IProps> = ({
  theme,
  size,
  w,
  icon,
  iconPosition,
  floatingIcon,
  disabled,
  loading,
  equalPadding,
  name,
  id,
  onClick,
  onMouseEnter,
  ref,
  style = {},
  children,
  ...restProps
}) => {
  const styles = componentStyles({
    icon,
    theme,
    size,
    w,
    disabled,
    equalPadding,
    iconPosition,
    floatingIcon,
    children,
  });

  const iconElement = (icon || loading) && (
    <Box style={styles.iconWrap}>
      {loading ? <LoadingCircle size={size === 'big' ? 30 : 20} color="white" /> : icon}
    </Box>
  );

  return (
    <FelaButton
      style={mergeStyles([styles.wrapper, style])}
      data-name={name || 'button-shell'}
      onPress={!loading ? onClick : undefined}
      onMouseEnter={onMouseEnter}
      ref={ref}
      disabled={disabled}
      {...restProps}>
      {iconPosition === 'left' && iconElement}
      {children && <Box style={styles.contentWrap}>{children}</Box>}
      {iconPosition === 'right' && iconElement}
    </FelaButton>
  );
};

export default ButtonShell;
