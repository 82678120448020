import React, { FunctionComponent } from 'react';
import Box, { IProps as IBoxProps } from './Box';
import { FelaCSS } from './flowtypes';

interface IProps extends IBoxProps {
  textAlign?: FelaCSS['textAlign'];
}

const Text: FunctionComponent<IProps> = ({ style, textAlign, ...restProps }) => {
  const textStyles: FelaCSS = {
    display: 'inline',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
    msHyphens: 'auto',
    textAlign,
    ...style,
  };

  return <Box {...restProps} style={textStyles} />;
};

export default Text;
